.news{
    width: 100%;
    min-height: 100%;
    background-color: #fff;
    position: relative;
    ::-webkit-scrollbar{
        width: 0px;
   }
   .loding{
    text-align: center;
   }
    .title{
        text-align: center;
        padding-top: 30px;
        font-size: 17px;
        font-weight: bold;
        color: #12171F;
        margin-bottom: 15px;
       
    }
    .detail{
        padding: 0 15px;
        height: 590px;
        overflow: hidden;
        overflow-y: auto;
        h1{
            font-size: 17px;
            font-weight: bold;
            color: #12171F;
            margin-bottom: 10px;
            word-break: break-all;
        }
        p{
            word-break: break-all;
        }
    }
    .news_list{
      
        height: calc(667px - 70px);
        overflow: hidden;
        overflow-y: auto;
        
        .active::after{
            content: '';
            display: inline-block;
            width: 6px;
            height: 6px;
            background: #F5222D;
            border-radius:50%;
            position: absolute;
            right: -6px;
            top: 0px;
        }
        .news_list_item{
            padding: 15px 0;
            border-bottom: 1px solid #EBEDF0; 
            display: flex;
            margin: 0 15px;
            img{
                width: 50px;
                height: 50px;
            }
            .info{
                margin-left: 10px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .top{
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    h2{
                        width: 200px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #12171F;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    p{
                        font-size: 12px;
                        font-weight: 500;
                        color: #C2C6CE;
                       
                    }
                }
                .bot{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 286px;
                    font-size: 14px;
                    color: #93979D;
                }
            }
        }
    }
}