.add{
    width: 100%;
    min-height: 100%;
    background-color: #fff;
    position: relative;
    .ant-radio-checked .ant-radio-inner {
        background-color: #006E80;
        border-color: #006E80;
   }
//    .ant-radio-wrapper{
//         width: 80px;
//    }
   .ant-radio-wrapper:not(.ant-radio-wrapper-disabled):hover .ant-radio-inner{
        border-color: #006E80;
   }
   .ant-radio-checked:after{
        border-color: 2px solid #006E80;
   }
    .user_photo{
        width: 72px;
        height: 72px;
        position: relative;
        left: 50%;
        margin-left: -36px;
        margin-top: 39px;
        margin-bottom: 30px;
        .upload{
            width: 72px;
            height: 72px;
            border-radius:50%;
            overflow: hidden;
        }
        .img2{
            position: absolute;
            width: 22px;
            height: 22px;
            bottom: 0;
            right: 0;
        }
        input{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }
    .title{
        text-align: center;
        padding-top: 30px;
        font-size: 17px;
        font-weight: bold;
        color: #12171F;
    }
    .form_btn{
        width: 375px;
        height: 72px;
        background: #FFFFFF;
        box-shadow: 0px -1px 8px 0px rgba(200,201,204,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        p{
            width: 335px;
            height: 40px;
            background: #006E80;
            border-radius: 4px;
            text-align: center;
            line-height: 40px;
            font-size: 14px;
            font-weight: bold;
            color: #FFFFFF;
        }   
    }
    .add_input{
        margin: 0 20px;
        margin-top: 20px;
        .form_input{
            display: flex;
            align-items: center;
            margin-top: 20px;
        }
        
        .input_time{
            width: 238px;
            height: 32px;
            position: relative;
            img{
                width: 18px;
                height: 18px;
                position: absolute;
                right: 10px;
                top: 7px;
            }
           
        }
        .form_input_u{
            display: flex;  
            margin-top: 20px;
            textarea{
                width: 238px;
                height: 84px;
                background: #FFFFFF;
                border: 1px solid #DCDEE0;
                padding: 6px 12px;
                resize:none;
            }
            p{
                padding-top: 6px;
            }
            .img_del{
                position: absolute;
                top: -10px;
                right: -10px;
                width: 20px;
                height: 20px;
            }
            .upload{
                width: 80px;
                height: 80px;
                position: relative;
            }
            input[type="file"]{
                width: 80px;
                height: 80px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;

            }
        }
        p{
            width: 90px;
            text-align: right;
            font-size: 14px;
            color: #12171F;
            margin-right: 8px;
        }
        input[type="text"]{
            width: 238px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #DCDEE0;
            padding: 0 12px;
        }
    }
    
}

.mt20{
    margin-top: 20px;               
}