.history_detail{
    width: 100%;
    height: 100%;
    background-color: #000;
    overflow: hidden;
    video{
        width: 100%;
        height: 100%;
        object-fit: contain;

    }
}
.history{
    width: 100%;
    min-height: 100%;
    background-color: #fff;
    position: relative;
    ::-webkit-scrollbar{
        width: 0px;
   }
    .title{
        text-align: center;
        padding-top: 30px;
        font-size: 17px;
        font-weight: bold;
        color: #12171F;
        margin-bottom: 20px;
    }
    .time{
        width: 100%;
        height: 41px;
        background: #F7F8FA;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
            font-size: 14px;
            color: #12171F;
        }
        .right{
            font-size: 14px;
            color: #93979D;
        }
    }
    .loding{
        text-align: center;
    }
    .home_list{
        margin: 0 15px;
        height: calc(667px - 80px);
        overflow: hidden;
        overflow-y: auto;
        .home_item{
            margin-top: 15px;
            border-bottom: 1px solid #EBEDF0;
            position: relative;
            .icon{
                width: 72px;
                height: 72px;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
        .home_item_bot{
            display: flex;
            justify-content: flex-end;
            padding-bottom: 15px;
            p{
                width: 88px;
                height: 32px;
                background: linear-gradient(270deg, #006E80 0%, rgba(0,110,128,0.7) 100%);
                border-radius: 50px;
                text-align: center;
                line-height: 32px;
                font-size: 14px;
                font-weight: bold;
                color: #FFFFFF;
                margin-left: 10px;
            }
        }
        .home_item_top{
            display: flex;
            margin-bottom: 10px;
            .img{
                width: 120px;
                height: 90px;
                border-radius: 5px;
            }
            .info{
                height: 90px;
                margin-left: 10px;
               
                flex: 1;
                width: 200px;
                
                .p1{
                    font-size: 16px;
                    font-weight: bold;
                    color: #12171F;
                    line-height: 1.2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .p2{
                    font-size: 14px;
                    color: #3C4658;
                    margin-top: 9px;
                }
                .p3{
                    font-size: 12px;
                    color: #93979D;
                    margin-top: 3px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .p4{
                    font-size: 12px;
                    color: #93979D;
                    margin-top: 7px;
                }
            }
        }
    }
}
.ant-tabs-nav{
    margin: 0 !important;
}