html,body{
    width: 100%;
    height: 100%;
    background-color: #EFF0F2;
    // overflow: hidden;
}
#root{
    width: 100%;
    height: 100%;
}
img{
    width: 100%;
    height: 100%;
}
.cursor{
    cursor: pointer;
    user-select:none
}
.jkb_btn{
    background-color: rgba(0, 110, 128, 1) !important;
}
.jkb_content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .jkb_main{
        width: 375px;
        height: 667px;
        margin: 0 auto;
        overflow: hidden;
        overflow-y: auto;
        position: relative;
        .close_small1{
            left: 0;
            right: 60px;
            position: absolute;
            height: 30px;
            top: 0;
            display: flex;
            z-index: 999;
        }
        .close_small{
            position: absolute;
            right: 10px;
            top: 10px;
            display: flex;
            z-index: 9999;
            justify-content: flex-end;
            .jkb_small{
                width: 15px;
                height: 15px;
                margin-right: 15px;
                cursor: pointer;
            }
            .jkb_close{
                width: 15px;
                height: 15px;
                cursor: pointer;
            }
        }
    }
    .jkb_main::-webkit-scrollbar{
        width: 0 !important;
        height: 0 !important;
        display: none !important;

    }
    .jkb_main{
        ::-webkit-scrollbar{
            width: 0 !important;
			height: 0 !important;
			display: none !important
        }
    }
}