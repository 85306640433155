.feedback{
    width: 100%;
    min-height: 100%;
    background-color: #fff;
    position: relative;
    .title{
        text-align: center;
        padding-top: 30px;
        font-size: 17px;
        font-weight: bold;
        color: #12171F;
        margin-bottom: 15px;
    }
    .content{
        padding: 0 15px;
        padding-top: 20px;
        h3{
            font-size: 14px;
            color: #12171F;
        }
        textarea{
            padding: 15px;
            width: 100%;
            resize:none;
            height: 129px;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #DCDEE0;
            margin-top: 15px;
        }
    }
    .btn{
        width: 311px;
        height: 50px;
        background: #006E80;
        border-radius: 25px;
        font-size: 20px;
        color: #FFFFFF;
        position: absolute;
        bottom: 30px;
        text-align: center;
        line-height: 50px;
        left: 50%;
        transform: translateX(-50%);
    }
}