.add{
    width: 100%;
    min-height: 100%;
    background-color: #fff;
    position: relative;
    .title{
        text-align: center;
        padding-top: 30px;
        font-size: 17px;
        font-weight: bold;
        color: #12171F;
    }
    .form_btn{
        width: 375px;
        height: 72px;
        background: #FFFFFF;
        box-shadow: 0px -1px 8px 0px rgba(200,201,204,0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        p{
            width: 335px;
            height: 40px;
            background: #006E80;
            border-radius: 4px;
            text-align: center;
            line-height: 40px;
            font-size: 14px;
            font-weight: bold;
            color: #FFFFFF;
        }   
    }
    .add_input{
        margin: 0 20px;
        margin-top: 20px;
        .form_input{
            display: flex;
            align-items: center;
        }
        
        .input_time{
            width: 238px;
            height: 32px;
            position: relative;
            img{
                width: 18px;
                height: 18px;
                position: absolute;
                right: 10px;
                top: 7px;
            }
           
        }
        .form_input_u{
            display: flex;  
            margin-top: 20px;
            textarea{
                width: 238px;
                height: 84px;
                background: #FFFFFF;
                border: 1px solid #DCDEE0;
                padding: 6px 12px;
                resize:none;
            }
            p{
                padding-top: 6px;
            }
            .img_del{
                position: absolute;
                top: -10px;
                right: -10px;
                width: 20px;
                height: 20px;
            }
            .upload{
                width: 80px;
                height: 80px;
                position: relative;
            }
            input[type="file"]{
                width: 80px;
                height: 80px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;

            }
        }
        p{
            width: 90px;
            text-align: right;
            font-size: 14px;
            color: #12171F;
            margin-right: 8px;
        }
        input[type="text"]{
            width: 238px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #DCDEE0;
            padding: 0 12px;
        }
    }
    
}
.adm-popup-body-position-bottom{
    width: 375px !important;
    left: 50% !important;
    margin-left: -187.5px !important;
    // transform: translateX(-50%) !important;
}
.adm-mask{
    width: 375px !important;
    height: 667px !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%) !important;

}
.mt20{
    margin-top: 20px;               
}
.DatePicker{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}