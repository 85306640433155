.share_pop {
    width: 100%;
    height: 100%;
    .talk{
        width: 100%;
        height: 100%;
        background: #121314;
        overflow: hidden;
        .video_dom{
            width: 100%;
            height: 130px;
            object-fit: contain;
        }
        .header{
            height: 36px;
            border-bottom:1px solid #000;
            display: flex;
            align-items: center;
            padding: 0 16px;
        }
        p{
            // width: 100px;
            padding-left: 16px;
            border-left: 2px solid #000;
            font-size: 12px;
            color: #FFFFFF;
            flex: 1;
        }
    }
}
html,body{
    background-color: transparent !important;
}
.open_video{
    width: 44px;
    height: 36px;
    background: #2D3033;
    border-radius: 20px 0 0 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.share_win{
    height: 60vh;
    overflow-y: auto;
    .list_win{
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;
    }
    .item_win{
        width: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 30px;
        margin-bottom: 30px;
        cursor: pointer;
        border: 1px solid #ccc;
    }
    .active {
        border:2px solid rgba(0, 110, 128, 1);
    }
    .win_p{
        width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.right_im{
    background-color: #fff;
    width: 24vw;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    .top{
        height: 55px;
        border-bottom: 1px solid  #EBEDF0;
        display: flex;
        align-items: center;
        padding-left: 20px;
        img{
            width: 24px;
            height: 24px;
        }
        p{
            font-size: 14px;
            color: #3C4658;
            margin-left: 10px;
           
        }
    }
    .person_top{
        border-bottom: 1px solid  #EBEDF0;
    }
    .top_p{
        border-bottom: 0;
    }
    .person_search{
        margin: 0px 20px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 40px;
        border: 1px solid #DCDEE0;
        display: flex;
        align-items: center;
        padding: 0 16px;
        margin-bottom: 17px;
        input{
            flex: 1;
        }
        img{
            width: 14px;
            height: 14px;
        }
    }
    .center{
        flex: 1;
        padding: 30px 40px;
        overflow: hidden;
        overflow-y: auto;
        p{
            font-size: 14px;
            color: rgba(18, 23, 31, 1);
            margin-bottom: 16px;
            .me{
                color: rgba(0, 110, 128, 1);
                position: relative;
            }
            .me::before{
                content: '[我]';
                color: rgba(255, 155, 0, 1);
                position: absolute;
                left: -26px;
            }
        }
        .person_list{
            padding-top: 20px;
            .person_item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom:10px;
                .left1{
                    display: flex;
                    align-items: center;
                    flex:1;
                    .img{
                        width: 30px;
                        height: 30px;
                        overflow: hidden;
                        border-radius: 50%;
                    }
                    .name{
                        margin-left: 10px;
                        flex:1;
                        h2{
                            font-size: 12px;
                            color: #3C4658;
                        }
                        h3{
                            font-size: 12px;
                            color: #93979D;
                        }
                    }
                }
                .right1{
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
    .bottom{
        display: flex;
        height: 80px;
        border-top: 1px solid #EBEDF0;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        .text{
            width: 100%;
            display: flex;
            border: 1px solid #006E80;
            border-radius: 4px;
            height: 52px;
        }
        textarea{
            resize: none;
            height: 100%;
            flex: 1;
            padding:8px 10px;
        }
        .send{
            width: 50px;
            height: 50px;
            background: #006E80;
            border-radius: 4px;
            text-align: center;
            line-height: 50px;
            font-size: 16px;
            font-weight: bold;
            color: #FFFFFF;
        }
        .person_btn{
            display: flex;
            align-items: center;
            .person_btn_item{
                padding: 5px 15px;
                font-size: 12px;
                color: #006E80;
                border-radius: 112px;
                border: 1px solid #DCDEE0;
                white-space: nowrap;
            }
        }
    }
}
.live {
    .content{
        height: calc(100vh - 68px);
        display: flex;
        justify-content: space-between;
        
        .left{
            background-color: #fff;
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .bottom{
                height: 80px;
                background-color: #fff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: 30px;
                margin-right: 20px;
                .item{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;
                    img{
                        width: 24px;
                        height: 24px;
                    }
                    p{
                        font-size: 12px;
                        color: #12171F;
                    }
                }
                .talk_v{
                    display: flex;
                }
                .talk_btn{
                    width: 120px;
                    height: 40px;
                    background: #006E80;
                    border-radius: 20px;
                    text-align: center;
                    line-height: 40px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
            .center{
                background: #000000;
                // height: 100%;
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                div{
                    height: calc(100vh - 55px - 80px - 64px) !important;
                }
                video{
                    object-fit: contain !important;
                }
            }
            .top{
                height: 55px;
                display: flex;
                align-items: center;
                margin-left: 30px;
                .photo{
                    width: 32px;
                    height: 32px;
                    border-radius:50%;
                    overflow: hidden;
                }
                h2{
                    font-size: 18px;
                    font-weight: bold;
                    color: #12171F;
                    margin-right: 30px;
                    margin-left: 10px;
                }
                .image{
                    width: 20px;
                    height: 20px;
                    margin-left: -10px;
                    border-radius:50%;
                    overflow: hidden;
                }
                p{
                    font-size: 14px;
                    color: #93979D;
                    margin-left: 4px;
                }
            }
        }
    }
    .header11{
        width: 80%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        z-index: 999;
    }
    .header{
        width: 100%;
        height: 48px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .left{
            height: 32px;
            padding-left: 30px;
        }
        .right{
            display: flex;
            align-items: center;
            .photo{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                overflow: hidden;
            }
            i{
                width: 1px;
                height: 18px;
                background-color: #E6E6E6;
                margin: 0 20px;
            }
            .small,.big,.close{
                width: 15px;
                height: 15px;
                margin-right: 15px;
                cursor: pointer;
                
            }
            .close{
                margin-right: 30px;
            }
        }
    }
}
.btn_list_v{
    width: 532px;
    height: 80px;
    background-color: #fff;
    align-items: center;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        user-select: none;
      
        img{
            width: 24px;
            height: 24px;
        }
        p{
            font-size: 12px;
            color: #12171F;
        }
    }
    .talk_v{
        flex: 1;
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        align-items: center;
    }
    .talk_btn{
        width: 120px;
        height: 40px;
        background: #006E80;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
    }
}