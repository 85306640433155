.my {
  width: 100%;
  min-height: 100%;
  background: url("~images/my_bg.png") no-repeat #fff;
  background-size: 100% 200px;
  padding: 0 15px;
  position: relative;
  .my_btn{
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 128px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #F30404;
    border-radius:4px;
    font-size: 17px;
    color: #F30404;
    text-align: center;
    line-height: 40px;
  }
  .my_list{
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
    border-radius: 8px;
    margin-top: 20px;
    .my_list_item{
        width: 100%;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        img{
            width: 16px;
            height: 16px;

        }
        p{
            font-size: 15px;
            color: #12171F;
        }
    }
  }
  .my_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 66px;
    .right {
      width: 22px;
      height: 22px;
    }
    .left {
      display: flex;
      align-items: center;
      .img {
        width: 56px;
        height: 56px;
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        overflow: hidden;
      }
      p {
        font-size: 17px;
        font-weight: bold;
        color: #ffffff;
        padding-left: 15px;
      }
    }
  }
}
