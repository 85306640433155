.jkb_logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 90px;
        height: 90px;
    }
    p{
        font-size: 20px;
        font-weight: bold;
        color: #12171F;
        margin-top: 8px;
    }
}