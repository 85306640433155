.login{
    width: 100%;
    height: 100%;
    background: url('~images/login_bg.png') no-repeat  #F8FAF9;;
    background-size:100% 326px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 75px;
    position: relative;
    .forget{
        width: 311px;
        font-size: 14px;
        color: #93979D;
        margin-top: 10px;
    }
    .login_btn{
        width: 311px;
        height: 50px;
        background: rgba(0, 110, 128, .3);
        border-radius: 25px;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        color: #FFFFFF;
    }
    .login_btn_bot{
        position: absolute;
        bottom: 30px;
    }
    .contact_phone{
        font-size: 17px;
        font-weight: bold;
        color: #12171F;
        margin-top: 71px;
    }
    .agrent{
        position: absolute;
        bottom: 57px;
        span{
            color: #006E80;
        }
       .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #006E80;
            border-color: #006E80;
            border-radius: 50%;
       }
       .ant-checkbox-checked:after{
            border:none;
            border-radius: 50%;
       }
       .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner{
            border-color: #006E80;
       }
       .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner{
            background-color: #006E80;    
            border-color: #006E80;
       }
       .ant-checkbox .ant-checkbox-inner {
            border-radius: 50%;
       }
        .agrent_p{
            font-size: 12px;
            color: #93979D;
            user-select: none;
        }
    }
}
.form_item{
    display: flex;
    width: 311px;
    height: 50px;
    background: #F2F4F5;
    border-radius: 25px;
    align-items: center;
    padding: 0 20px;
    .img1{
        width: 20px;
        height: 20px;
    }
    input{
        flex: 1;
        height: 100%;
        background: #F2F4F5;
        border-radius: 25px;
        padding-left: 4px;
    }
    input::placeholder{
        font-size: 16px;
        color: #93979D;
    }
    .img2{
        width: 22px;
        height: 22px;
    }
    p{
        font-size: 16px;
        color: #006E80;
        white-space: nowrap;
    }
}
.mt13{
    margin-top: 13px;
}
.mt30{
    margin-top: 30px;
}