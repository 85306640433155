.home{
    width: 100%;
    background: url('~images/home_bg.png') no-repeat #fff;
    background-size: 100% 150px;
    ::-webkit-scrollbar{
        width: 0px;
   }
   .loding{
    text-align: center;
   }
    .home_list{
        margin: 0 15px;
        height: calc(667px - 67px - 168px);
        overflow: hidden;
        overflow-y: auto;
        margin-top: 15px;
        .home_item{
            margin-top: 15px;
            border-bottom: 1px solid #EBEDF0;
            position: relative;
            .icon{
                width: 72px;
                height: 72px;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
        .home_item_bot{
            display: flex;
            justify-content: flex-end;
            padding-bottom: 15px;
            p{
                width: 88px;
                height: 32px;
                background: linear-gradient(270deg, #006E80 0%, rgba(0,110,128,0.7) 100%);
                border-radius: 50px;
                text-align: center;
                line-height: 32px;
                font-size: 14px;
                font-weight: bold;
                color: #FFFFFF;
            }
        }
        .home_item_top{
            display: flex;
            margin-bottom: 10px;
            .img{
                width: 120px;
                height: 90px;
                border-radius: 5px;
            }
            .info{
                height: 90px;
                margin-left: 10px;
               
                flex: 1;
                width: 200px;
                
                .p1{
                    font-size: 16px;
                    font-weight: bold;
                    color: #12171F;
                    line-height: 1.2;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .p2{
                    font-size: 14px;
                    color: #3C4658;
                    margin-top: 9px;
                }
                .p3{
                    font-size: 12px;
                    color: #93979D;
                    margin-top: 3px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .p4{
                    font-size: 12px;
                    color: #93979D;
                    margin-top: 7px;
                }
            }
        }
    }
    
    .home_tab{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .tab_list{
        width: 264px;
        height: 32px;
        background: #F7F8FA;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-top: 21px;
        .tab_list_item{
            width: 88px;
            height: 32px;
            line-height: 32px;
            font-size: 16px;
            color: #3C4658;
            cursor: pointer;
            user-select:none;
            text-align: center;
            position: relative;
            z-index: 99;
        }
        .active{
            color: #fff;
        }
        .tab_list_item_bot{
            position: absolute;
            width: 88px;
            height: 32px;
            background: linear-gradient(270deg, #006E80 0%, rgba(0,110,128,0.7) 100%);
            border-radius: 50px;
            transition: transform .3s;
        }
    }
    .add_history{
        display: flex;
        margin-top: 20px;
        justify-content: center;
        .add_history_item{
            width: 165px;
            height: 68px;
            background: linear-gradient(128deg, #EDFEFF 0%, #F5F8FF 100%);
            box-shadow: 0px 2px 8px 0px rgba(200,201,204,0.5);
            opacity: 1;
            border: 2px solid #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            img{
                width: 40px;
                height: 40px;
            }
            p{
                font-size: 15px;
                color: #12171F;
                margin-left: 10px;
            }
        }
        .add_history_item:last-child{
            background: linear-gradient(135deg, #FFFCEF 0%, #FFF8F5 100%);
            box-shadow: 0px 2px 8px 0px rgba(200,201,204,0.5);
            margin-left: 15px;
        }
    }
    .header{
        padding: 0 15px;
        padding-top: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right{
            width: 20px;
            height: 20px;
            position: relative;
            i{
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: rgba(243, 4, 4, 1);
                position: absolute;
                top: -2px;
                right: -2px;
            }
        }
        .left{
            display: flex;
            align-items: center;
            .photo{
                width: 36px;
                height: 36px;
                border: 2px solid rgba(255,255,255,0.5);
                border-radius: 50%;
                overflow: hidden;
            }
            p{
                font-size: 15px;
                font-weight: bold;
                color: #FFFFFF;
                margin-left: 10px;
            }
        }
    }
}